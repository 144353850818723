import Breadcrumbs from '~/components/breadcrumbs/Breadcrumbs'
import SubscribeFormLarge from '~/components/forms/SubscribeFormLarge'
import { Pagination } from '~/components/shared/Pagination'
import BlogPostList from '~/views/Blog/components/BlogPostList'

import * as containerStyles from './SearchResults.module.scss'

interface TransformParams {
  url: string
  page: number
}

type TransformUrl = (params: TransformParams) => string

interface SearchResultsProps {
  pages: number
  pageData: Queries.STRAPI_ARTICLE[]
  searchText: string
  currentPage: number
  searchResult: string
  transformUrl: TransformUrl
  displaySearchText: string
}

const SearchResults = ({
  pages,
  pageData,
  searchText,
  currentPage,
  searchResult,
  transformUrl,
  displaySearchText,
}: SearchResultsProps) => (
  <div className={containerStyles.searchResults}>
    <div className="container container-md">
      <div className="row">
        <div className="col-12">
          <div className={containerStyles.searchResults__breadcrumbs}>
            <Breadcrumbs
              secondLevel="Blog"
              secondLevelPath="/blog/"
              current={`Search Results for: ${displaySearchText}`}
              currentPath={`/blog/search/?query=${searchText}`}
            />
          </div>
          <h1 className={containerStyles.searchResults__title}>
            Search Results for:
          </h1>
          <h2 className={containerStyles.searchResults__query}>
            {displaySearchText}
          </h2>
        </div>

        <BlogPostList data={pageData} onlyPosts />

        {searchResult.length > 9 && (
          <div className="col-12">
            <div className={containerStyles.searchResults__pagination}>
              <Pagination
                countPages={pages}
                currentPage={currentPage}
                url={`/blog/search/?query=${searchText}`}
                transformUrl={transformUrl}
              />
            </div>
          </div>
        )}
      </div>
    </div>

    <div className={containerStyles.searchResults__subscribe}>
      <div className="container container-md">
        <SubscribeFormLarge
          title="Stay up-to-date and never miss a resource"
          description="Subscribe to Codica newsletter"
        />
      </div>
    </div>
  </div>
)

export default SearchResults
