import { graphql, useStaticQuery } from 'gatsby'

import notFoundIcon from '~/assets/images/icons/icon-star-red.svg?file'
import HeadSection from '~/components/blocks/HeadSection'
import RecentArticles from '~/components/blocks/RecentArticles'
import SocialBlock from '~/components/blocks/SocialBlock'
import SearchInput from '~/components/shared/SearchInput'
import { AllStrapiArticle, PhotosQuery } from '~/types/graphql'

import * as containerStyles from './SearchNotFound.module.scss'

const SearchNotFound = () => {
  const query = useStaticQuery<AllStrapiArticle & PhotosQuery>(graphql`
    query ($published: [Boolean]) {
      allStrapiArticle(
        limit: 3
        sort: { createdAt: DESC }
        filter: { active: { eq: true }, published: { in: $published } }
      ) {
        edges {
          node {
            id
            url
            preview_image_alt
            category {
              name
            }
            preview_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
                }
              }
            }
            title
            meta_description
            modified_date
            body {
              data {
                childMarkdownRemark {
                  timeToRead
                }
              }
            }
            authors {
              id
              name
              slug
              position
              description
              avatar {
                url
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                      quality: 100
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div className={containerStyles.searchNotFound}>
      <div className="container container-md">
        <div className="row">
          <div className="col-12">
            <div className={containerStyles.searchNotFound__wrapper}>
              <h1 className={containerStyles.searchNotFound__title}>
                No results found for
              </h1>
              <SearchInput />
              <div className={containerStyles.searchNotFound__descrWrapp}>
                <img
                  src={notFoundIcon}
                  className={containerStyles.searchNotFound__icon}
                  alt="Not Found page icon | Codica"
                  title="Not Found page icon"
                  width="32px"
                  height="34px"
                  loading="lazy"
                />
                <div className={containerStyles.searchNotFound__descrItem}>
                  You can ensure words are spelled correctly, try rephrasing
                  keywords or using synonyms, try more general keywords.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={containerStyles.searchNotFound__recentPosts}>
        <HeadSection
          position="left"
          title="In the meantime, take a look at our most recent articles:"
        />
        <RecentArticles data={query} buttonText="View all blog posts" />
      </div>
      <SocialBlock />
    </div>
  )
}
export default SearchNotFound
