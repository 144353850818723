import { graphql } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'
import { useEffect, useState } from 'react'

import SEO from '~/components/shared/Seo'
import {
  AllStrapiCategory,
  AllStrapiTag,
  QueryResult,
  StrapiPage,
} from '~/types/graphql'
import { PagePropsWithContext } from '~/types/helper'
import { fileToImageLikeData, getPageMeta } from '~/utils'
import SearchView from '~/views/Blog/views/Search'

export type SearchProps = StrapiPage &
  QueryResult<
    AllStrapiCategory &
      AllStrapiTag & { localSearchPages: { index: any; store: any } }
  >

const Search = ({ data }: SearchProps) => <SearchView data={data} />

export default Search

export const pageQuery = graphql`
  query {
    strapiPage(page_name: { eq: "Search" }) {
      ...MetaTagInformationPages
    }

    localSearchPages {
      index
      store
    }

    allStrapiCategory(sort: { order: ASC }) {
      edges {
        node {
          id
          url
          name
          nav_name
        }
      }
    }

    allStrapiTag(sort: { number_tag: ASC }) {
      edges {
        node {
          name
          url
          articles {
            active
          }
        }
      }
    }
  }
`

export const Head = ({ data }: PagePropsWithContext<SearchProps>) => {
  const { meta_title, meta_description, og_image } = getPageMeta(
    data.strapiPage,
  )
  const [searchText, setSearchText] = useState('')

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setSearchText(
        new URLSearchParams(window.location.search).get('query') || '',
      )
    }
  }, [])

  return (
    <SEO
      title={meta_title}
      description={meta_description}
      urlImage={getSrc(fileToImageLikeData(og_image?.localFile)) as string}
      url={`https://www.codica.com/blog/search/?query=${searchText}`}
      meta={[
        {
          name: 'robots',
          content: 'noindex',
        },
      ]}
    />
  )
}
