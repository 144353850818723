import { navigate } from 'gatsby'
import { useEffect, useMemo } from 'react'
import { useFlexSearch } from 'react-use-flexsearch'

import MainLayout from '~/components/layouts/MainLayout'
import Loader from '~/components/shared/Loader/Loader'
import { IS_BROWSER } from '~/constants/browser'
import { useClientSideRendering } from '~/hooks'
import SearchNotFound from '~/views/Blog/views/Search/components/SearchNotFound'

import SearchResults from './components/SearchResults'

const SearchView = ({ data }) => {
  const {
    localSearchPages: { index, store },
  } = data

  const isClient = useClientSideRendering()
  const search = IS_BROWSER ? window.location.search : ''

  const { currentPage, searchText, displaySearchText, limitResultOnPage } =
    useMemo(() => {
      const params = new URLSearchParams(search)
      const searchQuery = params.get('query') || ''
      const page = Number(params.get('p') || 1)
      const limit = 9

      return {
        searchText: searchQuery,
        displaySearchText: searchQuery.replace(/-/g, ' ').trim(),
        currentPage: page,
        limitResultOnPage: limit,
      }
    }, [search])

  const searchResult = useFlexSearch(searchText, index, store).sort(
    (a, b) =>
      new Date(b.modified_date).getTime() - new Date(a.modified_date).getTime(),
  )

  const pages = Math.ceil(searchResult.length / 9)
  const pageData = searchResult.slice(
    (currentPage - 1) * limitResultOnPage,
    currentPage * limitResultOnPage,
  )

  const transformUrl = ({ url, page }) => {
    const newQuery = new URLSearchParams(window.location.search)
    newQuery.set('p', page)

    return page === 1 ? url : `${url}&p=${newQuery.get('p')}`
  }

  useEffect(() => {
    if (isClient && pageData.length === 0 && searchText) {
      navigate(`/blog/search/?query=${encodeURIComponent(searchText)}`, {
        replace: true,
      })
    }
  }, [pageData.length, searchText, isClient])

  const isSearchNotFound = searchResult.length === 0

  return (
    <MainLayout isStaticChat scrollTop>
      {!isClient && <Loader />}
      {isClient && isSearchNotFound && <SearchNotFound />}
      {isClient && !isSearchNotFound && (
        <SearchResults
          pages={pages}
          pageData={pageData}
          searchText={searchText}
          currentPage={currentPage}
          searchResult={searchResult}
          transformUrl={transformUrl}
          displaySearchText={displaySearchText}
        />
      )}
    </MainLayout>
  )
}

export default SearchView
